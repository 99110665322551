<template>
  <div id="body">
    <el-button type="text" @click="porn" id="porn">换个主题？</el-button>
    <el-card class="box-card">
      <template #header>
        <div class="card-header">
          <span>河南大学男同协会</span>
          <el-button class="button" type="primary" plain
            ><a href="https://henu.gay" target="_blank">前往</a></el-button
          >
        </div>
      </template>
      <div class="text item">
        <p>
          通过创造一个多样化和包容性的社会环境，使男女同性恋、双性恋和变性者群体能够享有平等权利，享受健康、独立和有尊严的生活。
        </p>
      </div>
    </el-card>

    <el-card class="box-card">
      <template #header>
        <div class="card-header">
          <span>FTP(172.20.2.7)</span>
          <el-button class="button" type="primary" plain
            ><a href="http://ftp.henu.gay:2180" target="_blank"
              >前往</a
            ></el-button
          >
        </div>
      </template>
      <div class="text item">
        <p>这里是FTP(172.20.2.7),Java Web,数字图像处理等作业使用这个ftp</p>
      </div>
    </el-card>

    <el-card class="box-card">
      <template #header>
        <div class="card-header">
          <span>FTP(172.20.1.2)</span>
          <el-button class="button" type="primary" plain
            ><a href="http://ftp.henu.gay:2181" target="_blank"
              >前往</a
            ></el-button
          >
        </div>
      </template>
      <div class="text item">
        <p>这里是FTP(172.20.1.2),操作系统的作业用这个ftp</p>
        <p>使用20.X连接，不受vpn影响</p>
      </div>
    </el-card>

    <el-card class="box-card">
      <template #header>
        <div class="card-header">
          <span>CloudSack</span>
          <el-button class="button" type="primary" plain
            ><a href="http://ftp.henu.gay:22130/client" target="_blank"
              >前往</a
            ></el-button
          >
        </div>
      </template>
      <div class="text item">
        <p>学院的云平台(172.20.1.10)</p>
        <br />
        <b>暂时有点小bug，可以用，但不完全能用</b>
        <br />
        <p> </p>
      </div>
    </el-card>

    <el-card class="box-card">
      <template #header>
        <div class="card-header">
          <span>网盘</span>
          <el-button class="button" type="primary" plain
            ><a href="https://pan.dabiantai.xyz" target="_blank"
              >前往</a
            ></el-button
          >
        </div>
      </template>
      <div class="text item">
        <p>个人网盘</p>
        <p>会存放一些文件</p>
      </div>
    </el-card>
    <el-divider><i class="el-icon-star-on"></i></el-divider>
    <div id="notice">
      <el-button type="text" @click="dialogVisible = true">打开服务遇到问题? 点我查看提示</el-button>
      <el-dialog
        v-model="dialogVisible"
        title="提示信息"
      >
        <div>
          <p>如果有些服务遇到5XX错误，例如：504 Gateway Time-out</p>
          <p>那就说明可能是学院的vpn出了问题</p>
          <p>请耐心等待服务恢复</p>
          <br>
          <b>错误示例：</b>
          <br>
          <img src="../assets/error-exaple-500.png" style="max-width:80%;"/>
          <br>
          <p>如果FTP(172.20.1.2)打不开，那就很有可能是东苑的校园网问题</p>
          <p>如果是404之类的错误请联系我修复</p>
        </div>
      </el-dialog>
    </div>
  </div>
  <el-backtop />
</template>

<style>
a {
  text-decoration: none;
  color: black;
  font-size: 1.1rem;
}
.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.text {
  font-size: 14px;
}

.item {
  margin-bottom: 18px;
}

.box-card {
  width: 480px;
  height: 220px;
  margin: 20px 0;
}
#body {
  display: flex;
  flex-wrap: wrap;

  justify-content: space-around;
  align-items: center;
}
#notice {
  width: 90vw;
}
#porn{
    position: absolute;
    right: 0;
    top: 0;
}
</style>

<script >
import { defineComponent, ref } from "vue";

export default defineComponent({
  setup() {
    const dialogVisible = ref(false);
    return {
      dialogVisible,
    };
  },
  methods:{
    porn: function(){
      import('../css/ph.css');
      //require('../css/ph.css')
    },
  }
});
</script>
